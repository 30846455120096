<template>
    asd
</template>

<script>
export default {
    name: 'edit-profile',
};
</script>

<style scoped lang="less">

</style>
